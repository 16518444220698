
.loginImg{
    height: 50rem;
    width: 60rem;
} 
.loginLeft{
    background-color:rgba(12, 12, 151, 0.397);
    height: 100%; 
}
.LoginDet{ 
    padding-top: 2vh;
}
.Loginlogo {
    height: 100px;
    width: 320px;
    margin: auto;
    padding-top: 10px;
}
.login-button-azure,
.login-button-azure:hover,
.login-button-azure:focus{
    margin: auto;
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: #101828;
    font-weight: 600;
    font-size: 14px;
    border: gray 1px solid;
    border-radius: 5px;
    padding: 7px;
} 

.loginName{
    text-align: center;
    font-size: 22px;
    margin-top: 2rem;
    font-weight: 600;
    color: white;
}
.loginDes{
    text-align: center;
    font-size: 14px;
    margin-top: 1rem;
    font-weight: 400;
    color: white;
}
.loginForm{
    width: 25rem; 
    margin:auto;
}
.signUp{
    text-align: center;
    margin-top: 1rem;
    font-size: 14px;
    color: white;
}
.signUp a{
    text-decoration: none;
}
.loginInput{
    border: 2px solid;
    margin-top: 10px;
}
.googleLogo{
    height: 30px;
    width: 30px;
}