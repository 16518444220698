/*****************collegecareerreadiness css********************/
.cohortbox-carousel .product-item {
	margin: 0 5px;
}

.cohortbox {
	background-color: #16203f;
	border-radius: 7px;
	padding: 10px 15px;
}

.cohortheading p {
	color: white;
	font-weight: 100;
	font-size: 16px;
}

.cohortbox .cohrtconten span {
	color: white;
	font-size: 11px;
	font-weight: 100;
}

.cohortbox .cohrtconten p {
	color: white;
	font-size: 22px;
}
.studentenrolled{
	background-color: #2f3854;
}

.studentenrolled-box {
    display: flex;
    justify-content: start;
}

.studentenrolled-box p {
    color: #c5c5c5;
    font-size: 17px;
}
.numberofyear {
    display: flex;
    align-items: end;
}
.numberofyear h4 {
    font-size: 24px;
    color: #ffffff;
}
.numberofyear span {
    font-size: 12px;
    color: #dbdbdb;
}

.trending {
    display: flex;
    background: #28ae61;
    align-items: center;
    color: #fff;
    justify-content: space-between;
    border-radius: 20px;
    width: 52px;
    padding: 0px 4px;
    font-size: 13px;
}
.trending img {
    width: 15px;
    object-fit: contain;
}

.studentMeasures .p-tabview .p-tabview-nav {
    background: #ffffff00 !important;
    border: 0 !important;
    border-width: 0 !important;
}
.studentMeasures .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #162e46;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #162e46 transparent;
    background: #162e46;
    color: #ffffff;
    border-radius: 60px;
	font-size: 15px;
    font-weight: 400;
    margin: 0;
    padding: 15px 28px;
}

.studentMeasures .p-tabview .p-tabview-panels {
    background: #ffffff00 !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    height: 0 !important;
    background-color: #ffffff00 !important;
}

.chartbackdrop {
    background: rgba(35, 42, 60, 0.65);
    border-radius: 6px;
}
.chartbackdrop p {
    color: #fff;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 0.2px;
}

.studentMeasures .p-tabview-selected {
    background-color: rgb(13 35 76 / 0%);
}

.studentMeasures .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: #0000;
    color: #162e46;
}
.studentMeasures .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0rem #c7d2fe00;
}

.studentMeasures .p-tabview-nav-container {
    margin-bottom: 0px;
}

.collegejoined p {
    color: #fff;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 0.2px;
}

.collegejoined {
    background: #1a2333e6;
    border-radius: 6px;
}

.collegejoindata p {
    color: #fff;
    padding: 8px 10px;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
}

.collegejoindata .table-auto {
    width: 100%;
}
.collegejoindata .table-auto thead tr th {
    color: #fff;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2px;
    text-align: left;
}

.collegejoindata .table-auto tbody tr {
    border-top: 1px solid #5f5f5f;
}

.br0 {
    border-right: 0;
}
.yaxis-text {
    transform: rotate(270deg);
    font-size: 14px;
    letter-spacing: 1.0px;
}

.districtclimaterating {
    background: #1e252dad;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    position: relative;
}
.districtclimaterating .score {
    position: absolute;
    background: #2c4089;
    text-align: center;
    width: 64px;
    height: 140px;
    top: -25px;
}
.districtclimaterating .score:before {
    content: '';
    display: block;
    border-bottom: 25px solid rgb(44, 64, 137);
    border-top-color: rgb(44, 64, 137);
    border-right: 80px solid transparent;
    border-left-color: rgb(44, 64, 137);
    position: absolute;
    top: 0px;
    left: 58px;
    z-index: 0;
}
.districtclimaterating .score span {
    font-size: 9px;
    color: #fff;
}
.districtclimaterating .score h4 {
    font-size: 47px;
    color: #fff;
    text-align: center;
    position: relative;
    top: 26px;
}
.districtclimaterating .dist-data {
    width: 84%;
    margin-left: 74px;
}
.districtclimaterating .dist-data h5 {
    color: #fff;
    font-size: 20px;
}

.districtclimaterating .dist-data p {
    color: #fff;
    font-size: 11px;
}

.neighborhoodcrimeindex {
    background: #1e252dad;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    position: relative;
}

.neighborhoodcrimeindex .score {
    position: absolute;
    background: #129c83;
    text-align: center;
    width: 64px;
    height: 140px;
    top: -25px;
}
.neighborhoodcrimeindex .score:before {
    content: '';
    display: block;
    border-bottom: 25px solid rgb(18, 156, 131);
    border-top-color: rgb(18, 156, 131);
    border-right: 80px solid transparent;
    border-left-color: rgb(18, 156, 131);
    position: absolute;
    top: 0px;
    left: 58px;
    z-index: 0;
}

.neighborhoodcrimeindex .score span {
    font-size: 9px;
    color: #fff;
}
.neighborhoodcrimeindex .score h4 {
    font-size: 47px;
    color: #fff;
    text-align: center;
    position: relative;
    top: 26px;
}
.neighborhoodcrimeindex .dist-data {
    width: 84%;
    margin-left: 74px;
}
.neighborhoodcrimeindex .dist-data h5 {
    color: #fff;
    font-size: 20px;
}

.neighborhoodcrimeindex .dist-data p {
    color: #fff;
    font-size: 11px;
}

.incidents-measures .p-tabview-nav {
    background: #00000000 !important;
}

.incidents-measures .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: rgba(22, 46, 70, 0.7);
    border-color: rgba(22, 46, 70, 0.7);
    color: #ffffff;
    font-weight: 300;
}

.incidents-measures .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #dee2e6;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #dee2e6 transparent;
    background:#081631; 
    color: #ffffff;
    padding: 1.25rem;
    font-weight: 100;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
}

.incidents-measures .p-tabview .p-tabview-panels {
    background: rgba(22, 46, 70, 0.7);
    padding: 1.25rem;
    border: 0 none;
    color: #ffffff;
    font-weight: 100;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.incidents-measures .p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 0px solid #dee2e6;
    border-width: 0 0 0px 0;
    float: right;
}

.incidents-measures .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0rem #081631;
}

.incimea-box {
    background: #0f2137;
    border-radius: 7px;
    padding: 0;
    min-height: 261px;
    position: relative;
}

.incimea-box h4 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    padding: 10px 15px;
}

.incimea-box p {
    font-weight: 400;
    font-size: 22px;
}

.uptolasty {
    background: #6A1D25;
    font-size: 11px;
    padding: 3px 10px 3px 9px;
    height: 22px;
    border-radius: 34px;
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.uptolasty img {
    width: 11px;
    margin-right: 10px;
}

.uptolastyup {
    background: #1C6130;
    font-size: 11px;
    padding: 3px 10px 3px 9px;
    height: 22px;
    border-radius: 34px;
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.uptolastyup img {
    width: 11px;
    margin-right: 10px;
}

.echartMediumLevellineArea {
    height: 174px;
}
.echartMediumLevel {
    height: 106px;
    position: absolute;
    width: 100%;
    bottom: 7px;
    left: -4px;
}
.performingschools h4 {
    padding: 10px 15px;
    font-weight: 400;
    font-size: 17px;
}
.performingschools .table-auto {
    width: 100%;
}
.performingschools .table-auto thead tr th {
    padding: 5px 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 100;
    width: 200px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}

.performingschools .table-auto tbody tr td {
    padding:20px 10px;
    text-align: left;
    font-size: 9px;
    font-weight: 100;
    width: 200px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}

.trendingdown {
    display: flex;
    background: #6A1D25;
    align-items: center;
    color: #fff;
    justify-content: space-between;
    border-radius: 20px;
    width: auto;
    padding: 0px 4px;
    font-size: 10px;
}
.trendingdown img {
    width: 12px;
    object-fit: contain;
}

.trendingup {
    display: flex;
    background: #1C6130;
    align-items: center;
    color: #fff;
    justify-content: space-between;
    border-radius: 20px;
    width: auto;
    padding: 0px 4px;
    font-size: 10px;
}
.trendingup img {
    width: 12px;
    object-fit: contain;
}
.pagenation a {
    font-size: 10px;
    background: black;
    width: 118px;
    border-radius: 4px;
    text-align: center;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 11px;
}
.pageinput {
    font-size: 9px;
}
.pageinput input {
    width: 29px;
    height: 20px;
    background: #101b29;
    border: 1px solid #000;
    margin: 14px 10px;
    text-align: center;
}

.measures-progressbar img {
    width: 21px;
    padding: 22px 2px 0;
}
.measures-progressbar {
    padding: 2px 0;
    padding-right: 15px;
}
.measuprog {
    padding-right: 15px;
    position: relative;
}
.progresbar-lable {
    position: absolute;
    right: 20px;
    top: -1px;
    font-size: 14px;
}
.measures-progressbar h5 {
    font-size: 12px;
    margin-bottom: 6px;
}
.Measures-taken {
    padding-bottom: 19px;
}
.Measures-taken h4 {
    padding: 10px 15px;
    font-weight: 400;
    font-size: 17px;
}
.progresslabel {
    position: absolute;
    right: 13px;
    font-size: 13px;
}
.collegecohort-box {
    padding: 10px;
    background: #16203f;
    margin: 0 8px;
    border-radius: 9px;
}
.collegecohort-box .collegecohort-treadingdown {
    background: #28ae61;
    width: 35px;
    padding: 7px;
    border-radius: 5px;
}

.collegecohort-box .collegecohort-head h3 {
    color: #ffffff;
    font-weight: 100;
    font-size: 14px;
}
.collegecohort-box span {
    color: #fff;
    font-size: 11px;
    font-weight: 100;
}
.collegecohort-box h2 {
    font-size: 20px;
    color: #fff;
}

.cohortboxcarousel .p-carousel .p-carousel-content .p-carousel-next, .cohortboxcarousel .p-carousel .p-carousel-content .p-carousel-prev {
    background-color: hsla(0,0%,100%,.2)!important;
    border: 0;
    border-radius: 1px!important;
    color: #ffffff;
    height: 5rem!important;
    margin: 0;
    transition: background-color .2s,color .2s,box-shadow .2s;
    width: 2.5rem!important;
}

.cohortboxcarousel .p-carousel .p-carousel-indicators {
    visibility:hidden;
}

.strength-vertical-slider-active {
    display: flex;
    flex-flow: column wrap;
    background-image: linear-gradient(rgb(249, 249, 249) 0%, rgb(255, 255, 255) 40%, rgb(255, 255, 255) 100%);
    border-radius: 3px;
}
.strength-vertical-slider-active .firstrowtext {
    font-size: 11px;
    color: rgb(12, 27, 40);
    opacity: initial;
    padding: 9px 0;
    margin: 0;
}
.strength-vertical-slider-active .strenth-pers {
    color: #000;
    font-weight: 600;
    font-size: 19px;
    margin: 0;
    padding: 0;
}
.timetreanding-carousel .p-carousel .p-carousel-content .p-carousel-prev, .timetreanding-carousel .p-carousel .p-carousel-content .p-carousel-next {
    background-color: #ebebed;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    margin-right: 5px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 19%), -3px 4px 6px rgb(0 0 0 / 23%);
    cursor: pointer;
    border: 1px solid #d0d0d0;
    color: #444;
}
.timetreanding-carousel .p-carousel .p-carousel-content .p-carousel-prev span {
    font-size: 11px;
}
.timetreanding-carousel .p-carousel .p-carousel-content .p-carousel-next span {
    font-size: 13px;
}
.streweak-vertical-carousel .p-carousel .p-carousel-content .p-carousel-prev {
    display: block;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 8px;
    background: #7a777e;
    width: 22px;
    height: 22px;
    text-align: center;
    /* opacity: initial; */
}
.streweak-vertical-carousel .p-carousel .p-carousel-content .p-carousel-next {
    display: block;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 42px;
    background: #7a777e;
    width: 22px;
    height: 22px;
    text-align: center;
    /* opacity: initial; */
}
.streweak-vertical-carousel {
    position: relative;
}
.streweak-vertical-carousel .p-carousel .p-carousel-content .p-carousel-prev span {
    color: #1b1422;
    font-size: 14px;
    position: relative;
    top: -1px;
}

.streweak-vertical-carousel .p-carousel .p-carousel-content .p-carousel-next span {
    color: #1b1422;
    font-size: 14px;
    position: relative;
    top: 0px;
}
.streweak-vertical-carousel .p-carousel .p-carousel-item{
flex: inherit !important;
}
.dash-slid-img .p-carousel .p-carousel-content .product-item img {
    object-fit: cover;
}

.enrollselect .p-dropdown .p-dropdown-label {
    color: #fff;
    padding: 0 13px;
    line-height: 32px;
}
.enrollselect .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #ffffff;
}

.pagination {
    display: flex;
    justify-content: end;
    position: absolute;
    bottom: 7px;
    right: 7px;
}
.pagination a {
    padding: 0;
    background: #00000000;
    border: 0;
    color: #fff;
    font-weight: 300;
    font-size: 15px;
}
.pagination span {
    color: #fff;
    font-weight: 100;
    padding: 0 11px 0 0px;
}
.pagination a:hover {
    background: #00000000;
    color: #fff;
}

.pagination a img {
    margin-left: 5px;
    margin-right: 5px;
}

.studenteoc-civi .p-dropdown .p-dropdown-label {
    color: #fff;
    padding: 0 13px;
    line-height: 32px;
}

.studenteoc-civi .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #ffffff;
}
.mnfcts-carimg img {
    object-fit: scale-down;
}

.streweak-vertical-carousel .p-carousel .p-carousel-content .p-carousel-container .p-carousel-items-content .p-carousel-items-container .p-carousel-item:nth-child( odd ) {
    background: #27304a !important;
}
.streweak-vertical-carousel .p-carousel .p-carousel-content .p-carousel-container .p-carousel-items-content .p-carousel-items-container .p-carousel-item:nth-child( even ) {
    background: #1e2033 !important;
}

@media (max-width: 640px) { 
	.numberofyear .counting {
		margin-right: 24%;
	}
	.studentenrolled-box {
		border-bottom: 2px solid #dddddd;
	}
 }

@media (min-width: 768px) {
	.numberofyear .counting {
		margin-right: 37%;
	}
 }

 @media (min-width: 1024px) { 
	.numberofyear .counting {
		margin-right: 42%;
	}
  }

/*****************collegecareerreadiness css********************/